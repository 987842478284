import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import ImageGalleryWithLightbox from "../../components/imageGalleryWithLightbox"

const Shiatsu = ({data}) => {

  // Single Image Data
  const images = {
    image1: data.image1.childImageSharp.fluid,
    image2: data.image2.childImageSharp.fluid,
    image3: data.image3.childImageSharp.fluid,
    image4: data.image4.childImageSharp.fluid,
  }

  const title = "Shiatsu"

  return (
    <Layout title={title} slogan="Bewegen und bewegt werden für Senioren" headerImage={data.image1.childImageSharp.fluid}>
      <div className="grid grid-2">
        <div>
          <h4>Shiatsu Herkunft</h4>
          <p>
            Shiatsu ist eine in Japan entwickelte Form der Körpertherapie die aus der traditionellen chinesischen Massage TUINA hervorgegangen ist. Am Anfang des 20 Jahrhunderts wurden in Japan verschiedene Formen der energetischen Körperarbeit und manuellen Behandlungsmethoden kombiniert und unter dem Namen SHIATSU vereint. Wörtlich übersetzt bedeutet SHIATSU „Fingerdruck“. In Europa wird Shiatsu vielfach als Entspannungsmethode angewandt.
          </p>
          <h4>Shiatsu Programm besteht aus 2 Teilen:</h4>
          <p>
            <b>Bewegungstechnik</b> bietet ein müheloses Training der körperlichen und geistigen Flexibilität. Die Übungen sind hilfreiche und konkrete Massnahmen zur Prophylaxe. Sie beugen Kontrakturen vor, machen Gelenke beweglicher oder wirken unterstützend auf die Nahrungsaufnahme. So fördert die Übung „Rumpf strecken“ z. B. die Beweglichkeit der Hüftgelenke, verbessert den Energiefluss, regt die Atmung an und unterstützt die Fähigkeit sich zu drehen.
            <br/><b>Shiatsu arbeitet mit Berührung</b> und ist deshalb eine wertvolle Ergänzung zur Pflege und Betreuung im Alter. Körperkontakt, Wärme und Berührung werden von älteren Menschen meist als angenehm und wohltuend empfunden. Spannungen und Blockaden werden gelöst und der Energiefluss harmonisiert, Körper und Seele werden zugleich berührt und unterstützt.
            Einen Schwerpunkt bildet häufig die Behandlung von Nacken und Schulter, da sich oft die Belastung des ganzen Lebens in dieser Zone äussern und die Behandlungen entsprechend grosse Erleichterung bringen kann.
          </p>
          <p>
            Samurai-Shiatsu-Programm gibt Lebenskraft und doch Entspannung.
            <br/>Bei Fragen steht Ihnen Lana Sinagra gerne zur Verfügung.
            <br/>Tel: 071 622 14 11
          </p>

        </div>
        <div>
          <ImageGalleryWithLightbox images={Object.values(images)} />
        </div>
      </div>
    </Layout>
  ) 
}

export default Shiatsu

export const pageQuery = graphql`
  query {
    image1: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-angebot-shiatsu-1.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    image2: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-angebot-shiatsu-2.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    image3: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-angebot-shiatsu-3.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    image4: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-angebot-shiatsu-4.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
  }
`